<template>
  <div class="embed-table">
    <div
      ref="scrollContainer"
      :class="[
        dragContainerClassname,
        { 'embed-table__scroll-container': !embedLevel, hasHorizontalOverflow },
      ]"
    >
      <draggable-list
        v-model="rows"
        style="flex-grow: 1"
        :options="{
          handle: '.embed-table__drag-handler',
          group: draggableGroupUid,
          disabled: dragDisabled,
        }"
        @startDrag="startDrag"
        @stopDrag="stopDrag"
        @change="dragItem"
      >
        <div
          v-for="(row, rowIndex) of rowsForDisplay"
          :key="row.id"
          :class="[
            'embed-table__row-drag-group',
            { 'embed-table__row-drag-group--form': inlineFormControls },
          ]"
        >
          <embed-table-row
            v-if="inlineFormControls || !singleType || rowIndex === 0"
            v-dragscroll.pass="{ container: '.' + dragContainerClassname }"
            @dragscrollstart="dragscrollStart"
            @dragscrollmove="dragscrollMove"
            @dragscrollend="dragscrollEnd"
          >
            <embed-table-cell
              v-for="(column, columnIndex) of tablesColumns[row.type]"
              :key="column.name"
              :width="column.width"
              :class="['embed-table__head', { required: column.required }]"
              :style="rowShiftStyle(columnIndex)"
              header
            >
              <a-icon
                v-if="column.name === 'actions'"
                class="embed-table__icon"
                type="setting"
                @click="startConfigEdit(row.type)"
              />
              <VueDraggableResizable
                v-else
                :handles="['mr']"
                :draggable="false"
                :minWidth="100"
                :w="column.width"
                h="auto"
                active
                preventDeactivation
                @resizing="(x, y, width) => resize(columnIndex, width)"
              >
                <span v-if="column.index === '_'">
                  {{ getLocalizedTypeName(row.type) }}
                </span>
                <span v-else>
                  {{ getLocalizedColumnName(row.type, column) }}
                  <a-tooltip
                    v-if="!!getColumnInfo(row.type, column)"
                    placement="topLeft"
                    class="embed-table__info"
                    :getPopupContainer="getPopupContainer"
                    :align="{
                      offset: [-8, 4],
                    }"
                  >
                    <template slot="title">{{ getColumnInfo(row.type, column) }}</template>
                    <a-icon type="question-circle" />
                  </a-tooltip>
                </span>
              </VueDraggableResizable>
            </embed-table-cell>
          </embed-table-row>
          <embed-table-row>
            <template v-for="(column, index) of tablesColumns[row.type]">
              <embed-table-cell
                v-if="column.index === '_'"
                :key="column.name"
                :width="column.width"
                :style="rowShiftStyle(index)"
              >
                <template v-if="editable">
                  <a-icon
                    class="embed-table__icon embed-table__drag-handler"
                    type="drag"
                  />
                  <a-popconfirm
                    v-if="editable && (config.multiple || embedLevel)"
                    :okText="$t('embed.deletionConfirm')"
                    :cancelText="$t('embed.deletionCancel')"
                    :title="$t('embed.deletionTitle')"
                    :getPopupContainer="getPopupContainer"
                    @confirm="deleteItem(row)"
                  >
                    <a-icon
                      class="embed-table__icon"
                      type="delete"
                    />
                  </a-popconfirm>
                  <edit-form-embed-create-button
                    v-if="rowIndex === rowsForDisplay.length - 1"
                    class="embed-table__array-create"
                    :config="config"
                    :formConfigs="formConfigs"
                    @click="reemit('createItem', arguments)"
                  >
                    <a-icon
                      class="embed-table__icon"
                      type="plus"
                    />
                  </edit-form-embed-create-button>
                </template>
              </embed-table-cell>
              <embed-table-cell
                v-else
                :id="focusId + '-' + row.id + '-' + column.name"
                :key="column.name"
                v-dragscroll.pass="{ container: '.' + dragContainerClassname }"
                :width="column.width"
                :style="rowShiftStyle(index)"
                @dragscrollstart="dragscrollStart"
                @dragscrollmove="dragscrollMove"
                @dragscrollend="dragscrollEnd"
                @click="editCell($event, row, column)"
              >
                <template v-if="column.name !== 'actions' && column.index">
                  <table-cell
                    v-if="!inlineFormControls || column.renderer === 'component'"
                    :column="column"
                    :value="getColumnValue(row, column)"
                    :entity="row"
                    :placeholder="getColumnPlaceholder(column)"
                    :arrayConfig="getFieldConfig(row.type, row.id, column.name)"
                    :formConfigs="getNestedFormConfigs(row.type, column.name)"
                    exp
                    :canUpdateModel="editable"
                    :active="expandedRows[row.id] === column.name"
                    :disabled="disabled"
                    :readOnly="readOnly"
                    @deleteItem="deleteItem(row, column.name)"
                    @createItem="
                      (itemType) => {
                        createNestedItem(itemType, row.type, column, row);
                      }
                    "
                    @checkboxChanged="checkboxChanged"
                    @toggleExpand="toggleExpand(row.type, column, row)"
                  />
                  <template v-else>
                    <edit-form-field-wrap
                      v-if="!column.hidden"
                      v-model="row.data[column.name]"
                      :disabled="disabled"
                      :readOnly="readOnly"
                      :fieldDescriptor="getFieldConfig(row.type, row.id, column.name)"
                      :parentType="row.type"
                      disabledRenderer="inhert"
                      hideTitle
                      :showTitleAppendix="false"
                    />
                  </template>
                </template>
              </embed-table-cell>
            </template>
          </embed-table-row>
          <div
            v-if="expandedRows[row.id]"
            :key="row.id + 'expanded'"
            class="embed-table__nested-container"
          >
            <edit-form-embed
              v-model="row.data[expandedRows[row.id]]"
              :config="getFieldConfig(row.type, row.id)"
              :parentType="row.type"
              :embedLevel="embedLevel + 1"
              :embedParentRowId="row.id"
              :embedParentField="embedParentField"
              :parentFocusId="focusId + '-' + row.id"
              :dragClassname="dragContainerClassname"
              :disabled="disabled"
              :readOnly="readOnly || !editable"
              :inlineFormControls="inlineFormControls"
            />
          </div>
        </div>
      </draggable-list>
    </div>

    <a-popover
      :visible="editingCell.show"
      trigger="click"
      placement="bottomLeft"
      overlayClassName="table-edit-popover table-edit-popover__no-icon"
      :getPopupContainer="getPopupContainer"
      :align="popupAlign"
    >
      <template #content>
        <table-cell-editable
          v-model="editingCell.value"
          :column="editingCell.column"
          :entity="editingCell.entity"
          :showIcon="false"
          isInlineTable
          @stopEdit="stopEditCell"
          @input="editCellInput"
        />
      </template>
    </a-popover>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';
import VueDraggableResizable from 'vue-draggable-resizable';
import store from '@/store';
import { bus, deepFind, uniqueId, isSystemField, getFieldLabel, getFieldInfo } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import lockScroll from '@/components/base/lockScroll.mixin';
import scrollElementInView from '@/components/base/scrollElementInView.mixin';
import DraggableList from '@/components/base/DraggableList';
import TableCell from '@/components/page/table/TableCell';
import TableCellEditable from '@/components/page/table/TableCellEditable';
import EditFormEmbedCreateButton from '../EditFormEmbedCreateButton';
import EditFormEmbed from '../EditFormEmbed';
import EmbedTableRow from './EmbedTableRow';
import EmbedTableCell from './EmbedTableCell';

const DEFAULT_COLUMN_WIDTH = 250;

export default {
  name: 'EmbedTable',

  mixins: [lockScroll, scrollElementInView],

  components: {
    EditFormEmbed,
    TableCell,
    TableCellEditable,
    EmbedTableRow,
    EmbedTableCell,
    DraggableList,
    VueDraggableResizable,
    EditFormEmbedCreateButton,
    EditFormFieldWrap: () => import('../../../EditFormFieldWrap.vue'),
  },
  directives: {
    dragscroll,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
    defaultSort: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      required: true,
    },
    formConfigs: {
      type: Object,
      required: true,
    },
    parentType: {
      type: String,
      required: true,
    },
    focusId: {
      type: String,
      required: true,
    },
    entityFields: {
      type: Array,
      default: () => [],
    },
    embedLevel: {
      type: Number,
      default: 0,
    },
    embedParentRowId: {
      type: String,
      default: null,
    },
    embedParentField: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
    dragClassname: {
      type: String,
      default: null,
    },
    inlineFormControls: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      draggableGroupUid: uniqueId(),
      editingCell: {
        show: false,
        entity: null,
        column: null,
        value: null,
        container: null,
      },
      expandedRows: {},
      draggscrollActive: false,
      dragscrollDistance: 0,
      hasHorizontalOverflow: false,
    };
  },

  computed: {
    dragContainerClassname() {
      return this.dragClassname || `dragscroll-${this._uid}`;
    },
    dragDisabled() {
      return (
        !this.desktop ||
        this.disabled ||
        this.readOnly ||
        this.rows.length < 2 ||
        this.editingCell.show
      );
    },
    singleType() {
      return this.config.types.length <= 1;
    },
    tables() {
      return (
        this.rows?.reduce((acc, { type, data, id }) => {
          if (!isSystemField(data)) {
            acc[id] = [{ data, type, id }];
          }
          return acc;
        }, {}) || {}
      );
    },
    tablesColumns() {
      const columnWidths = this.config.columnWidth.split(',').map((x) => Number(x));
      const isConstWidth = columnWidths.length === 1;

      return Object.entries(this.config.typesDict).reduce((acc, [type, config]) => {
        acc[type] = this.formConfigs[type].columns
          .filter((column) => !column.hidden)
          .map((column, index) => {
            column = {
              ...(config.fields.find((field) => column.name === field.name) || {}),
              ...column,
              index: column.index || `data.${column.name}`,
              width:
                (isConstWidth ? columnWidths[0] : columnWidths[index + 1]) || DEFAULT_COLUMN_WIDTH,
            };

            delete column.typesDict;
            return column;
          });

        acc[type].unshift({
          label: type,
          index: '_',
          width: columnWidths[0] || DEFAULT_COLUMN_WIDTH,
        });

        acc[type].push({
          name: 'actions',
          label: '',
          width: 50,
        });

        return acc;
      }, {});
    },
    desktop() {
      return store.state.isDesktop;
    },
    rows() {
      return this.prepareRowsDataForAnt(this.data) || [];
    },
    rowsForDisplay() {
      return this.rows.filter(({ data }) => !isSystemField(data));
    },
    editable() {
      return !this.readOnly && !this.disabled;
    },
    popupAlign() {
      return {
        target: this.editingCell.container,
        offset: [0, 0],
        points: ['tl', 'tl'],
      };
    },
  },

  created() {
    bus.$on('embedAutofocus', this.checkAndSetAutofocus);
    bus.$on('switchEntityType', this.onEntityTypeSwitch);
    bus.$on('changeEmbedPage', this.stopEditCell);
    bus.$on('resetForm', this.stopEditCell);

    this.expandedRows =
      this.rows
        ?.filter((row) => !isSystemField(row.data))
        .reduce((acc, row) => ({ ...acc, [row.id]: null }), {}) || null;
  },

  mounted() {
    this.checkAndSetAutofocus();
    this.updateOverflowWidth();

    document
      .querySelector('.entity-modal, .entity-page')
      .addEventListener('click', this.modalClick);
  },

  updated() {
    this.checkAndSetAutofocus();
    this.updateOverflowWidth();
  },

  beforeDestroy() {
    bus.$off('embedAutofocus', this.checkAndSetAutofocus);
    bus.$off('switchEntityType', this.onEntityTypeSwitch);
    bus.$off('changeEmbedPage', this.stopEditCell);
    bus.$off('resetForm', this.stopEditCell);

    document
      .querySelector('.entity-modal, .entity-page')
      .removeEventListener('click', this.modalClick);
  },

  methods: {
    editCellInput() {
      this.$emit(
        'updateItem',
        this.editingCell.entity,
        this.editingCell.column.name,
        this.editingCell.value,
      );
    },

    updateOverflowWidth() {
      const width = parseInt(getComputedStyle(this.$refs.scrollContainer).width);
      this.hasHorizontalOverflow = this.$refs.scrollContainer.scrollWidth > width;
    },

    onEntityTypeSwitch(field) {
      if (this.embedLevel === 0 && this.config.name === field) {
        Object.keys(this.expandedRows).forEach((id) => {
          this.expandedRows[id] = null;
        });
      }
    },

    isAFocusTargetLevel() {
      return store.state.embedAutofocus.level === this.embedLevel;
    },

    isAFocusTargetField() {
      return store.state.embedAutofocus.level
        ? store.state.embedAutofocus.parentField === this.embedParentField
        : store.state.embedAutofocus.parentField === this.config.name;
    },

    isAFocusTargetRow() {
      const parentRowId = store.state.embedAutofocus.parentRowId;
      return !parentRowId || parentRowId === this.embedParentRowId;
    },

    async checkAndSetAutofocus() {
      if (store.state.embedAutofocus) {
        const focusData = store.state.embedAutofocus;
        if (this.isAFocusTargetLevel() && this.isAFocusTargetField() && this.isAFocusTargetRow()) {
          const targetRow = this.data.find((row) => String(row.id) === focusData.rowId);
          if (!targetRow) return;

          const cell = document.getElementById(focusData.id);
          const column = this.config.typesDict[targetRow.type].fields.find(
            ({ name }) => name === focusData.column,
          );

          store.state.embedAutofocus = null;
          this.editCell(cell, targetRow, column);
        }
      }
    },

    resize(columnIndex, width) {
      const columnWidths = this.config.columnWidth.split(',').map((x) => Number(x));

      const res = [];
      for (let i = 0, lim = Math.max(columnIndex + 1, columnWidths.length); i < lim; i++) {
        res.push(i === columnIndex ? width : columnWidths[i] || 250);
      }

      // because if only one number present in config it applies to all columns
      if (columnIndex === 0 && res.length === 1) {
        res.push(250);
      }

      if (this.embedLevel === 0) {
        FormConfigService.updateFormConfigField(
          this.parentType,
          this.config.name,
          'columnWidth',
          res.join(),
          this,
        );
      } else {
        FormConfigService.updateEmbedConfigField(
          this.parentType,
          this.config.name,
          'columnWidth',
          res.join(),
          this,
        );
      }

      this.$nextTick(() => {
        bus.$emit('monaco.updatelayout');
      });
    },

    async dragItem({ moved }) {
      if (!moved) return;
      const { oldIndex, newIndex } = moved;
      this.$emit('moveItem', this.rows[oldIndex], newIndex - oldIndex);
    },

    startDrag() {
      this.dragging = true;
    },

    stopDrag() {
      Object.keys(this.expandedRows).forEach((id) => {
        this.expandedRows[id] = null;
      });

      // Prevent firefox from registring click event
      setTimeout(() => {
        this.dragging = false;
      }, 50);
    },

    dragscrollStart() {
      this.draggscrollActive = true;
    },

    dragscrollMove({ deltaX }) {
      this.dragscrollDistance += Math.abs(deltaX);

      if (this.dragscrollDistance >= 3) {
        this.stopEditCell(true);
      }
    },

    dragscrollEnd() {
      setTimeout(() => {
        this.dragscrollDistance = 0;
        this.draggscrollActive = false;
      }, 50);
    },

    getColumnValue(row, column) {
      return column.index ? deepFind(row, column.index) : '';
    },

    getColumnPlaceholder(column) {
      if (!column.name || isSystemField(column)) return '';
      return this.$t('base.placeholder.enterField', { name: column.name });
    },

    toggleExpand(type, column, row) {
      if (this.expandedRows[row.id] === column.name) {
        this.expandedRows[row.id] = null;
      } else {
        this.expandedRows[row.id] = column.name;
      }

      this.expandedRows = { ...this.expandedRows };
    },

    getNestedFormConfigs(type, fieldName) {
      const typeDescriptor = this.config.typesDict[type];
      const fieldDescriptor = typeDescriptor.fields.find((field) => field.name === fieldName);
      if (!['component', 'embed'].includes(fieldDescriptor.renderer)) return null;

      return fieldDescriptor.types.reduce((acc, childType) => {
        acc[childType] = FormConfigService.getEmbedConfig(childType);
        return acc;
      }, {});
    },

    getFieldConfig(type, id, field) {
      field = field || this.expandedRows[id];
      return {
        ...this.config.typesDict[type].fields.find((x) => x.name === field),
        ...this.formConfigs[type].columns.find((x) => x.name === field),
      };
    },

    customHeaderRow(columns) {
      return {
        class:
          columns.filter((col) => col.dataIndex?.startsWith('data.')).length < 1
            ? 'nofieldsheadrow'
            : '',
      };
    },

    canEditCell(column) {
      return (
        this.editable &&
        !isSystemField(column) &&
        [
          'string',
          'integer',
          'date',
          'time',
          'date-time',
          'text',
          'wysiwyg',
          'image',
          'gallery',
          'color',
          'json',
          'attachment',
          'handbook',
          'ref',
          'refs',
          'ref2',
          'ref-like',
          'enum',
          'gpoint',
          'array',
        ].includes(column.renderer)
      );
    },

    async editCell(event, row, column) {
      if (this.inlineFormControls) return;
      if (this.draggscrollActive) return;
      if (this.disable || this.readOnly) return;
      if (!this.canEditCell(column)) return;

      const container = event.currentTarget || event;
      const targetCellAlreadyInEdit = this.editingCell.container === container;

      if (this.editingCell.show) {
        this.stopEditCell();
      }

      if (!targetCellAlreadyInEdit) {
        await this.scrollElementInView(
          event.currentTarget || event,
          this.getMarginsByRenderer(column.renderer),
        );

        this.lockEntityFormScroll();

        this.editingCell = {
          show: true,
          showTime: new Date(),
          entity: row,
          column,
          container,
          value: row.data[column.name],
        };
      }
    },

    getLocalizedTypeName(type) {
      return this.formConfigs[type].locale[store.state.lang].entity || type;
    },

    getLocalizedColumnName(type, column) {
      return getFieldLabel(
        this.formConfigs[type].locale[store.state.lang].fields,
        column,
        store.state.lang,
      );
    },

    getColumnInfo(type, column) {
      return getFieldInfo(this.formConfigs[type].locale[store.state.lang].fields, column);
    },

    modalClick(event) {
      // Clicks not from inside popup or popup opening
      if (!event.path.includes(this.editingCell.container)) {
        this.stopEditCell();
      }
    },

    stopEditCell(ignoreTimeout = false) {
      if (!this.editingCell.show) return;
      if (!ignoreTimeout && new Date() - this.editingCell.showTime < 50) return;

      this.editingCell = {
        show: false,
        showTime: Infinity,
        entity: null,
        column: null,
        value: null,
        container: null,
      };

      this.lockEntityFormScroll(false);
    },

    prepareRowsDataForAnt(rows) {
      return rows?.map((row) => ({ ...row, key: row.id })) || [];
    },

    checkboxChanged(event, entity, field, checked) {
      this.$emit('updateItem', entity, field, !checked);
    },

    getNewItem(type) {
      return {
        type,
        data: {},
        id: String(this.rows.length),
      };
    },

    createNestedItem(itemType, type, column, row) {
      this.expandedRows[row.id] = column.name;
      this.$emit('createNestedItem', itemType, column.name, row.id);
    },

    deleteItem(row, columnName = null) {
      this.$emit('deleteItem', row, columnName);
    },

    getPopupContainer() {
      return document.querySelector('.entity-modal, .entity-page');
    },

    startConfigEdit(type) {
      bus.$emit('editEmbedConfig', {
        type,
        localConfig: this.formConfigs[type],
        descriptors: this.config.typesDict[type].fields,
      });
    },

    rowShiftStyle(index) {
      return index === 0
        ? {
            paddingLeft: `${this.embedLevel * 20}px`,
          }
        : {};
    },
  },
};
</script>

<style lang="scss">
.embed-table {
  position: relative;

  .vdr:before {
    outline: none;
  }

  .handle-mr {
    right: -25px;
    position: absolute;
    width: 25px;
    height: 30px;
    cursor: e-resize;
    top: 50%;
    margin-top: -15px;
    z-index: 5;
  }

  &__info {
    padding: 4px;
    opacity: 0.5;
  }

  .embed-table-cell:first-child {
    font-weight: 500;
  }

  &__scroll-container {
    overflow-x: auto;
    @include scrollbars();
    display: flex;
  }

  &__head {
    span {
      display: block;
      color: #808080;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.required:after {
      margin-left: 2px;
      font-weight: 500;
      content: '*';
    }
  }

  &__nested-container {
    & > .entity-field {
      margin-bottom: 0;
    }
  }

  &__icon {
    cursor: pointer;
  }

  &__row-drag-group--form {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: #d0d0d0;
      content: '';
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &:not(:first-child) {
      margin-top: 20px;
    }

    .embed-table-cell:not(.embed-table__head) {
      align-items: flex-start;
      line-height: 30px;
      & > span {
        flex-grow: 1;
        & > .entity-field {
          margin-bottom: 0;
        }
      }
    }
  }

  .table.ant-table-wrapper {
    overflow-x: initial;
  }
}
</style>
