<template>
  <div
    :class="['embed-table-cell', { 'embed-table-cell--header': header }]"
    :style="cellStyle"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EmbedTableCell',
  props: {
    width: {
      type: Number,
      default: 250,
    },
    header: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cellStyle() {
      return {
        width: `${this.width}px`,
        maxWidth: `${this.width}px`,
      };
    },
  },
};
</script>

<style lang="scss">
.embed-table-cell {
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 16px;
  color: #101010;

  // &:hover {
  //   z-index: 1;
  // }

  // &:not(&--header) {
  //   width: 200px;
  //   max-width: 200px;
  // }

  // .table-cell {
  //   width: 100%;
  // }

  // .tags {
  //   max-width: 100%;
  // }

  &:last-child {
    width: auto;
  }

  &--header {
    &:not(&--noborder) + & {
      &::before {
        position: absolute;
        left: 0;
        width: 1px;
        height: 1.6em;
        background: #e8e8e8;
        content: '';
      }
    }
  }

  .embed-table__icon {
    visibility: hidden;
    pointer-events: none;
    margin-right: 8px;
  }

  .tags {
    margin-top: -14px;
    margin-bottom: -4px;
  }
}
</style>
