<template>
  <div class="embed-table-row">
    <div class="embed-table-row__grid">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmbedTableRow',
};
</script>

<style lang="scss">
.embed-table-row {
  @include scrollbars();
  border-bottom: 1px solid #e8e8e8;

  &__grid {
    display: inline-grid;
    grid-auto-flow: column;
    grid-auto-rows: min-content;
    overflow: auto;
    vertical-align: top;
  }

  &:hover > &__grid > .embed-table-cell .embed-table__icon {
    visibility: visible;
    pointer-events: initial;
  }
}
</style>
